import { service } from '../index'
const base = process.env.NODE_ENV === 'production' ? '' : ''
// 获取用户登录凭证token
export const getAuthorizeInfo_ = params => {
  params = params || {}
  params.tenantId = global.APP_CONFIG.tenantId
  return service.get('/wxapi/auth/v1/app/authorize', { headers: { noSuccToast: false }, params })
}

// 获取当前用户信息
export const getUsersCurrentInfo = async() => {
  return service.get('/wxapi/auth/v1/app/user', { headers: { noSuccToast: true }})
}

//  获取JSAPI签名信息
export const getJssdkConfig = params => {
  params = params || {}
  params.tenantId = global.APP_CONFIG.tenantId
  params.appId = global.APP_CONFIG.appId
  return service.post('/wxapi/qw-proxy-gateway/v1/ticket/app/web/jsapi-sign', params, { headers: { noSuccToast: true }})
}

// 获取 运营后台token
export const getAuthorizeInfo = params => {
  params = params || {}
  return service.post(`${base}/gateway-api/eagoal-auth/v1/auth/wecom/redirect/h5?code=${params.code}`, { headers: { noSuccToast: false }})
}
