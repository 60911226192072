export default {
  namespaced: true,
  state: {
    authorizeInfo: null,
    usersCurrentInfo: null,
    jssdkConfigStatus: false
  },
  mutations: {
    authorizeInfo: function(state, payload) {
      state.authorizeInfo = payload
    },
    usersCurrentInfo: function(state, payload) {
      state.usersCurrentInfo = payload
    },
    jssdkConfigStatus: function(state, payload) {
      state.jssdkConfigStatus = payload
    }
  },
  getters: {
    getAuthorizeInfo: state => state.authorizeInfo,
    getUsersCurrentInfo: state => state.usersCurrentInfo,
    getJssdkConfigStatus: state => state.jssdkConfigStatus
  },
  actions: {

  }
}
