<template>
  <div id="app" @click="_showConsole">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style lang="less" scoped>
  #app{
    width: 100vw;
    height: 100vh;
    background: #F9F9FA;
  }
</style>
