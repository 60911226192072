// 非生产环境默认展示vconsole。todo：后续改成rancher中window.JSCONFIG.showVconsole控制。
import Vue from 'vue'
import Vconsole from 'vconsole'
let count = 0
let duage = true
let debugOn = true

// if (process.env.VUE_APP_ENV !== 'prod' || window.JSCONFIG.showVconsole) {
//   const vConsole = new Vconsole()
//   Vue.use(vConsole)
// }

if (process.env.VUE_APP_ENV !== 'prod') {
  const vConsole = new Vconsole()
  Vue.use(vConsole)
}
Vue.prototype._showConsole = function() {
  if (process.env.VUE_APP_ENV === 'prod') {
    count++
    setTimeout(() => {
      if (count === 6 && duage) {
        const vConsole = new Vconsole()
        Vue.use(vConsole)
        duage = false
        if (debugOn) {
          document.getElementById('__vconsole').style.display = 'block'
          debugOn = false
        }
        else {
          document.getElementById('__vconsole').style.display = 'none'
          debugOn = true
        }
      }
      else {
        count = 0
        duage = true
      }
    }, 2000)
  }
}
