import { get } from '../index'
const base = process.env.NODE_ENV === 'production' ? '' : ''

// 获取配置变量
export const getGlobal = data => get(`${base}/public/global`, data)

// 获取code
export const getCode = data => get(`${base}/public/weixCode`, data)

// 使用用户token 换取 用户权限集
export const tokenGetRole = data => get(`${base}/public/v1/auth/myResource`, data)

export default {
  getGlobal,
  tokenGetRole,
  getCode
}
