import axios from 'axios'
import store from '../store/index'
import { Toast } from 'vant'
import { convertLongInt } from '../scripts/utils'
import { setUserAuth } from '../scripts/userAuth'

global.APP_CONFIG = global.APP_CONFIG || {}
let toast = null
let toastMsg = null

export const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_PATH || '',
  baseURL: '',
  timeout: 20000,
  responseType: 'text',
  transformResponse: [
    data => {
      return data
    }
  ]
})

const login = () => {
  setUserAuth({
    authType: global.APP_CONFIG.auth_type,
    authFn: () => {}
  })
}

service.interceptors.request.use(
  config => {
    // config.headers['x-token'] = store.getters['userAuth/getAuthorizeInfo']
    //   ? store.getters['userAuth/getAuthorizeInfo'].token
    //   : ''
    // 企微鉴权
    // config.headers['userId'] = store.getters['userAuth/getAuthorizeInfo']
    //   ? store.getters['userAuth/getAuthorizeInfo'].qwUserId
    //   : ''
    // //Bcenter鉴权
    // config.headers['userId'] = store.getters['userAuth/getAuthorizeInfo']
    // ? store.getters['userAuth/getAuthorizeInfo'].qwUserId
    // : ''
    // config.headers['orgId'] = store.getters['userAuth/getUsersCurrentInfo']
    //   ? store.getters['userAuth/getUsersCurrentInfo'].orgId
    //   : ''
    // config.headers['orgList'] = store.getters['userAuth/getUsersCurrentInfo']
    //   ? store.getters['userAuth/getUsersCurrentInfo'].org
    //   : ''
    if (config.url.includes('public') && store.getters['userAuth/getAuthorizeInfo']) {
      config.headers.token = store.getters['userAuth/getAuthorizeInfo'].token
    }
    if (!config.headers['noSuccToast']) {
      toast = Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    if (toast && !toastMsg) {
      toast.clear()
    }
    const res = JSON.parse(convertLongInt(response.data)) // Long型数值转化
    if (res.code === 50002) {
      // 令牌不存在或已失效
      login()
    }
    else {
      if (res.code !== 200 && !response.config.headers['noErrToast']) {
        toastMsg = Toast.fail({
          message: res.msg,
          onClose: () => {
            toastMsg = null
          }
        })
      }
    }
    return res
  },
  error => {
    const res = JSON.parse(convertLongInt(error.response.data))
    if (res.code === 50002) {
      // 令牌不存在或已失效
      login()
    }
    if (toast && !toastMsg) {
      toast.clear()
    }
    return Promise.reject(error)
  }
)

export function post(url, data, headers) {
  return service({
    method: 'post',
    url,
    data,
    headers: { headers }
  })
}

export function get(url, params, headers) {
  return service({
    method: 'get',
    url,
    params,
    headers: { headers }
  })
}

export function put(url, data, headers) {
  return service({
    method: 'put',
    url,
    data,
    headers: { headers }
  })
}
