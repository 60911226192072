import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  base: '',
  mode: 'history',
  scrollBehavior(_to, _from, _savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: []
})

/**
 * 自动集合modules下模块路由配置
 */
const setRoute = function() {
  const requireRouter = require.context('@/views', true, /route\.js$/)
  requireRouter.keys().forEach(fileName => {
    // fileName为文件路径名
    const routerConfig = requireRouter(fileName)
    // 动态添加路由
    if (routerConfig.default) {
      if (Array.isArray(routerConfig.default)) {
        router.addRoutes(routerConfig.default)
        routerConfig.default.map(item => {
          router.options.routes.push(item)
        })
      }
      else {
        router.addRoutes([routerConfig.default])
        router.options.routes.push(routerConfig.default)
      }
    }
  })
  const notFound = { path: '*', name: 'notfound', component: () => import('@/components/404') }
  router.addRoutes([notFound])
  router.options.routes.push(notFound)
}

setRoute()

router.beforeEach(async(to, _from, next) => {
  document.title = to.meta.routeName || ''
  next()
})

export default router
