import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import Vant from 'vant'

// import '@/scripts/cla'  // 监控
// import '@/scripts/rum'  // 性能监控
// import '@/scripts/wx'
import 'amfe-flexible'
import '@/scripts/pageAdapt'
import '@/scripts/vconsole'
import 'normalize.css/normalize.css'
import 'vant/lib/index.less'
import '@/assets/styles/vant.less'
// import '@/assets/styles/common.less'
import api from './api/axios'

Vue.config.ignoredElements = ['wx-open-launch-weapp']
Vue.prototype.$api = api
Vue.use(Vant)

Vue.config.productionTip = false
const vue = new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

export default vue
