const route = [
  {
    path: '/',
    name: 'submit',
    component: () => import(/* webpackChunkName: 'activity' */ '@/views/recycle/form'),
    meta: { routeName: '云上信息' }
  },
  {
    path: '/recyclEnter',
    name: 'enter',
    component: () => import(/* webpackChunkName: 'activity' */ '@/views/recycle/enter'),
    meta: { routeName: '信息上报完成' }
  }
]

export default route
