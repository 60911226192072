import { post, get } from '../index'
// 获取联系人照片
export const getPeopleImg = data => get(`/gateway-api/eagoal-supply/v1/recycle-battery/supply/agent`, data)

// 获取列表
export const huijiList = data => post(`/admin-api/api/v1/find_feiji`, data)

// ADD
export const huijiAdd = data => post(`/admin-api/api/v1/add_feiji`, data)

export default {
  getPeopleImg,
  huijiAdd,
  huijiList
}
