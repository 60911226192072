import { getAuthorizeInfo, getUsersCurrentInfo } from '@/api/modules/userAuth'
import { urlEncode, getUrlParamsObj, delUrlParams } from '@/scripts/utils'
import store from '@/store/index'
import vue from '@/main'

/**
 * 根据场景类型接入对应权限
 * @param params 当前模块权限相关配置对象
 */
export const setUserAuth = function(params) {
  const { authType, authFn } = params
  if (authType === 'webAuthLogin') {
    const corpId = global.APP_CONFIG.corpId
    const appId = global.APP_CONFIG.appId
    getWebAuthCode(corpId, appId)
  }
  else if (authType === 'scanCodeAuthLogin') {
    // 扫码授权登录，待接入...
  }
  else if (authType === 'customAuthLogin') {
    authFn && authFn(global.APP_CONFIG)
  }
}

/**
 * 企微网页授权获取授权码
 * @param corpId 应用所属企业id
 * @param appId 当前应用id
 */
export const getWebAuthCode = function(corpId, appId) {
  const code = getUrlParamsObj().code
  const redirectUri = urlEncode(window.location.href)
  const oauth2Url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${corpId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&state=1#wechat_redirect`
  if (code == null || code === '') {
    window.location.href = oauth2Url
  }
  else {
    getAuthorizeInfoFn(code, appId)
  }
}

/**
 * 企微授权登录获取登录凭证token
 * @param code 授权链接返回的授权码
 * @param appId 当前应用id
 * @param oauth2Url 授权链接
 */
export const getAuthorizeInfoFn = function(code, appId) {
  getAuthorizeInfo({ code, appId }).then(
    async res => {
      if (res.code === 0) {
        store.commit('userAuth/authorizeInfo', res.data)
        const qs = JSON.parse(JSON.stringify(vue.$route.query)) // 深拷贝
        delete qs.code
        delete qs.state
        if (!vue.$route.name) {
          location.href = delUrlParams(location.href, ['code', 'state'])
        }
        else {
          vue.$router.replace({ query: qs })
        }
      }
    },
    err => {
      window.console.log(err, 'authorizeInfo-err')
    }
  )
}

/**
 * 企微网页授权登录获取当前用户信息
 * @param oauth2Url 授权链接
 */
export const getUser = function() {
  return new Promise((resolve, reject) => {
    getUsersCurrentInfo().then(
      res => {
        if (res.code === 0) {
          store.commit('userAuth/usersCurrentInfo', res.data)
          resolve(res)
        }
      },
      err => {
        window.console.log(err, 'usersCurrentInfo-err')
        reject(err)
      }
    )
  })
}
